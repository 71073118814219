import InputRadio from "@js/component/InputRadio";
import clsx from "clsx";
import Input from "@js/component/Input";
import React, {DetailedHTMLProps, FormEvent, forwardRef, HTMLAttributes, Ref } from "react";

import * as classNames from "@css/component/ContactPersonFormInputs.module.scss";

/**
 * @type ContactPersonFormInputsProps
 */
export type ContactPersonFormInputsProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
    contactTitle: string;
    onSetContactTitle: (event: FormEvent<HTMLInputElement>) => void;
    contactInitials: string;
    onSetContactInitials: (event: FormEvent<HTMLInputElement>) => void;
    contactAffix: string;
    onSetContactAffix: (event: FormEvent<HTMLInputElement>) => void;
    contactName: string;
    onSetContactName?: (event: FormEvent<HTMLInputElement>) => void;
    contactPhone: string;
    onSetContactPhone: (event: FormEvent<HTMLInputElement>) => void;
    contactEmail: string;
    onSetContactEmail: (event: FormEvent<HTMLInputElement>) => void;
    onShowProposition?: boolean;
    promotion?: {
        question: string;
    }
    propositionChecked?: string;
    onSetPropositionChecked?: (event: FormEvent<HTMLInputElement>) => void;
    contactCompanyName?: string;
    onSetContactCompanyName?: (event: FormEvent<HTMLInputElement>) => void;
    buildingOwner?: boolean;
    altContactData?: boolean;
    errors: {
        buildingOwnerTitle?: [];
        buildingOwnerInitials?: [];
        buildingOwnerAffix?: [];
        buildingOwnerName?: [];
        buildingOwnerPhone?: [];
        email?: [];
        contactCompanyName?: string;
    };
};

const ContactPersonFormInputs = (props: ContactPersonFormInputsProps, ref: Ref<HTMLDivElement>) => {
    const {
        contactTitle,
        onSetContactTitle,
        contactInitials,
        onSetContactInitials,
        contactAffix,
        onSetContactAffix,
        contactName,
        onSetContactName,
        contactPhone,
        onSetContactPhone,
        contactEmail,
        onSetContactEmail,
        onShowProposition,
        promotion,
        propositionChecked,
        onSetPropositionChecked,
        contactCompanyName,
        onSetContactCompanyName,
        buildingOwner,
        altContactData,
        errors,
        ...restProps
    } = props;


    return (
        <div { ...restProps } ref={ ref }>
            <fieldset className={ clsx(classNames.fieldset, classNames.padding) }>
                <legend className={ classNames.legend }>Aanhef</legend>
                <div className={ clsx(classNames.group, classNames.title) }>
                    <InputRadio
                        checked={ contactTitle == "m" }
                        labelClassName={ classNames.inputRadio }
                        name={ buildingOwner ? "title" : "contactPersonTitle" }
                        onChange={ onSetContactTitle }
                        required={ (altContactData || buildingOwner) }
                        value="m"
                        autoComplete="honorific-prefix"
                    >Dhr.</InputRadio>
                    <InputRadio
                        checked={ contactTitle == "f" }
                        labelClassName={ classNames.inputRadio }
                        name={ buildingOwner ? "title" : "contactPersonTitle" }
                        onChange={ onSetContactTitle }
                        required={ (altContactData || buildingOwner) }
                        value="f"
                        autoComplete="honorific-prefix"
                    >Mevr.</InputRadio>
                </div>
            </fieldset>
            <ul className={ classNames.errors }>
                { errors.buildingOwnerTitle &&
                    errors.buildingOwnerTitle.map((error, index) => (
                        <li className={ classNames.error } key={ index }>{ error }</li>
                    )) }
            </ul>
            { buildingOwner &&
                <div>
                    <label className={ classNames.label } htmlFor="companyname">Bedrijfsnaam <small>(optioneel)</small></label>
                    <Input
                        className={ clsx(classNames.input, classNames.contactCompanyName, {
                            [classNames.error]: errors.contactCompanyName
                        }) }
                        id="companyname"
                        name="companyname"
                        onChange={ onSetContactCompanyName }
                        value={ contactCompanyName }
                        autoComplete="organization"
                    />
                </div>
            }
            <div className={ clsx(classNames.group, classNames.flex) }>
                <div>
                    <label className={ classNames.label } htmlFor="initials">Voornaam</label>
                    <Input
                        className={ clsx(classNames.input, classNames.contactInitials, {
                            [classNames.error]: errors.buildingOwnerInitials
                        }) }
                        id="initials"
                        name="initials"
                        onChange={ onSetContactInitials }
                        required={ (altContactData || buildingOwner) }
                        value={ contactInitials }
                        autoComplete="given-name"
                    />
                </div>
                <div>
                    <label className={ classNames.label } htmlFor="affix">Tussenvoegsel</label>
                    <Input
                        className={ clsx(classNames.input, classNames.contactAffix, {
                            [classNames.error]: errors.buildingOwnerAffix
                        }) }
                        id="affix"
                        name="affix"
                        onChange={ onSetContactAffix }
                        value={ contactAffix }
                        autoComplete="additional-name"
                    />
                </div>
                <div>
                    <label className={ classNames.label } htmlFor="name">Achternaam</label>
                    <Input
                        className={ clsx(classNames.input, classNames.contactName, {
                            [classNames.error]: errors.buildingOwnerName
                        }) }
                        id="name"
                        name="name"
                        onChange={ onSetContactName }
                        required={ (altContactData || buildingOwner) }
                        value={ contactName }
                        autoComplete="family-name"
                    />
                </div>
            </div>
            <ul className={ classNames.errors }>
                { errors.buildingOwnerInitials &&
                    errors.buildingOwnerInitials.map((error, index) => (
                        <li className={ classNames.error } key={ index }>{ error }</li>
                    )) }
                { errors.buildingOwnerAffix &&
                    errors.buildingOwnerAffix.map((error, index) => (
                        <li className={ classNames.error } key={ index }>{ error }</li>
                    )) }
                { errors.buildingOwnerName &&
                    errors.buildingOwnerName.map((error, index) => (
                        <li className={ classNames.error } key={ index }>{ error }</li>
                    )) }
            </ul>
            <label className={ classNames.label } htmlFor="phone">Telefoonnummer</label>
            <Input
                className={ clsx(classNames.input, {
                    [classNames.error]: errors.buildingOwnerPhone
                }) }
                id="phone"
                name="phone"
                onChange={ onSetContactPhone }
                placeholder="0612345678"
                required={ (altContactData || buildingOwner) }
                value={ contactPhone }
            />
            <ul className={ classNames.errors }>
                { errors.buildingOwnerPhone &&
                    errors.buildingOwnerPhone.map((error, index) => (
                        <li className={ classNames.error } key={ index }>{ error }</li>
                    )) }
            </ul>
            <label className={ classNames.label } htmlFor="email">E-mailadres</label>
            <Input
                className={ clsx(classNames.input, {
                    [classNames.error]: errors.email
                }) }
                id="email"
                name="email"
                onChange={ onSetContactEmail }
                required={ (altContactData || buildingOwner) }
                type="email"
                value={ contactEmail }
            />
            <ul className={ classNames.errors }>
                { errors.email &&
                    errors.email.map((error, index) => (
                        <li className={ classNames.error } key={ index }>{ error }</li>
                    )) }
            </ul>
            { onShowProposition && promotion &&
                <fieldset className={ clsx(classNames.fieldset, classNames.proposition) }>
                    <legend className={ classNames.legend }>{ promotion.question }</legend>
                    <div className={ clsx(classNames.group, classNames.title) }>
                        <InputRadio
                            required
                            checked={ propositionChecked == "yes" }
                            labelClassName={ classNames.inputRadio }
                            name="proposition"
                            onChange={ onSetPropositionChecked }
                            value="yes"
                        >Ja</InputRadio>
                        <InputRadio
                            required
                            checked={ propositionChecked == "no" }
                            labelClassName={ classNames.inputRadio }
                            name="proposition"
                            onChange={ onSetPropositionChecked }
                            value="no"
                        >Nee</InputRadio>
                    </div>
                </fieldset>
            }
        </div>
    );
};

export default forwardRef(ContactPersonFormInputs);