// extracted by mini-css-extract-plugin
export var addition = "ContactPersonFormInputs-module--addition--VkQtO";
export var contactAffix = "ContactPersonFormInputs-module--contactAffix--Hdg5M";
export var contactCompanyName = "ContactPersonFormInputs-module--contactCompanyName--226vs";
export var contactInitials = "ContactPersonFormInputs-module--contactInitials--jvntn";
export var contactName = "ContactPersonFormInputs-module--contactName--vSdg1";
export var error = "ContactPersonFormInputs-module--error--fNpYX";
export var errors = "ContactPersonFormInputs-module--errors--+eiRA";
export var fieldset = "ContactPersonFormInputs-module--fieldset--WqtPS";
export var flex = "ContactPersonFormInputs-module--flex--PieM2";
export var group = "ContactPersonFormInputs-module--group--dO2Ta";
export var houseNumber = "ContactPersonFormInputs-module--houseNumber--iilB+";
export var input = "ContactPersonFormInputs-module--input--j5ubB";
export var inputRadio = "ContactPersonFormInputs-module--inputRadio--9jiL4";
export var label = "ContactPersonFormInputs-module--label--hcbHu";
export var labelAddition = "ContactPersonFormInputs-module--labelAddition--NyIvY";
export var labelHouseNumber = "ContactPersonFormInputs-module--labelHouseNumber--AIMNB";
export var legend = "ContactPersonFormInputs-module--legend--HnJ60";
export var padding = "ContactPersonFormInputs-module--padding--hN-X6";
export var proposition = "ContactPersonFormInputs-module--proposition--z81eW";
export var textarea = "ContactPersonFormInputs-module--textarea--3281-";
export var title = "ContactPersonFormInputs-module--title--fEfEm";
export var zipCode = "ContactPersonFormInputs-module--zipCode--JnU5A";