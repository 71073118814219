import React, {DetailedHTMLProps, HTMLAttributes, useState} from "react";

import * as classNames from "@css/component/SiteFaqQuestion.module.scss";
import clsx from "clsx";

export type SiteFaqQuestionProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
    question: string;
    answer: string;
    variant?: "home";
};

const SiteFaqQuestion = (props: SiteFaqQuestionProps) => {
    const {
        question,
        answer,
        variant,
    } = props;

    const [isShowQuestion, setIsShowQuestion] = useState(false);

    return (
        <div className={ clsx(classNames.faq, {
            [classNames.open]: isShowQuestion,
            [classNames.home]: "home" === variant
        }) }>
            <label onClick={ () => setIsShowQuestion( isShowQuestion ? false : true ) }>
                { question }
            </label>
            <div className={ classNames.answer } dangerouslySetInnerHTML={{ __html: answer }} />
        </div>
    );
};

export default SiteFaqQuestion;