import React from "react";

import { DetailedHTMLProps } from "react";
import { TextareaHTMLAttributes } from "react";

import clsx from "clsx";

import * as classNames from "@css/component/Textarea.module.scss";

/**
 * @type TextareaProps
 */
export type TextareaProps = Omit<DetailedHTMLProps<TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>, "children">;

/**
 * @const Textarea
 */
const Textarea = (props: TextareaProps) => {
    const {
        className,
        ...restProps
    } = props;

    return (
        <textarea { ...restProps } className={ clsx(classNames.textarea, className) } />
    );
};

export default Textarea;
