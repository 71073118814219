import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";

import { Helmet } from "react-helmet";

import { IGatsbyImageData } from "gatsby-plugin-image";
import { MouseEvent } from "react";
import { RouteComponentProps } from "@reach/router";

import clsx from "clsx";

import { graphql } from "gatsby";
import { useStaticQuery } from "gatsby";

import { createRef } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";

import Box from "@js/component/Box";
import Button from "@js/component/Button";
import CheckIcon from "@js/component/icon/CheckIcon";
import Heading from "@js/component/Heading";
import InputCheckbox from "@js/component/InputCheckbox";
import InputRadio from "@js/component/InputRadio";
import LongArrowRightIcon from "@js/component/icon/LongArrowRightIcon";
import Main from "@js/component/Main";
import Textarea from "@js/component/Textarea";

import StoreState from "@js/store/StoreState";
import AddressFormInputs from "@js/component/AddressFormInputs";
import ContactPersonFormInputs from "@js/component/ContactPersonFormInputs";
import {setAdvisorUrl, setHasCompleted, setHasVisited} from "@js/actionCreators/informationActionCreators";
import AdvisorReviewsScore from "@js/component/AdvisorReviewsScore";
import SiteFaqs from "@js/component/SiteFaqs";
import useDebounce from "@js/hook/useDebounce";

import * as classNames from "@css/component/template/Request.module.scss";
import AdvisorReviews from "@js/component/AdvisorReviews";
import DisplayProductChoice from "@js/component/DisplayProductChoice";

/**
 * @type ReviewData
 */
type ReviewData = {
    advisor: {
        companyImage: {
            originalName: string;
            url: string;
        };
        companyName: string;
        deliveryTime: number;
        full: boolean;
        id: number;
        reviewAveragePercentage: number;
        reviewTotalCount: number;
        reviews: {
            advisor: number;
            recentReviews: {
                advisor: number;
                billingCity: string;
                billingHouseNumber: number;
                billingHouseNumberSuffix: string;
                billingStreet: string;
                billingZipcode: string;
                buildingOwnerAffix: string;
                buildingOwnerCompanyName: string;
                buildingOwnerInitials: string;
                buildingOwnerName: string;
                buildingOwnerTitle: string;
                createdAt: string;
                deliveryTime: number;
                review: number;
                reviewMessage: string;
                status: string;
                url: string;
            }[];
            scores: {
                1: number;
                2: number;
                3: number;
                4: number;
                5: number;
            };
            stats: {
                averageScore: number;
                totalReviews: number;
                wilsonScore: number;
            };
        }[];
        url: string;
    };
};

/**
 * @type AllAdvisorData
 */
type AllAdvisorData = {
    allAdvisor: {
        edges: {
            node: {
                companyImageFile: {
                    childImageSharp: {
                        gatsbyImageData: IGatsbyImageData;
                    }
                },
                databaseId: number;
            };
        }[];
    }
};

/**
 * @type AllFaqData
 */
type AllFaqData = {
    allWordpressFaq: {
        edges: {
            node: {
                content: string;
                title: string;
            };
        }[];
    }
};

/**
 * @type FilterData
 */
type FilterData = {
    filter: {
        options: {
            methodology: {
                label: string;
                value: string;
            }[];
        };
    };
};

/**
 * @type LogoPlaceholderData
 */
type LogoPlaceholderData = {
    logoPlaceholder: {
        childImageSharp: {
            gatsbyImageData: IGatsbyImageData;
        };
    };
};


/**
 * @type Data
 */
type Data = AllAdvisorData & AllFaqData & FilterData & LogoPlaceholderData;

/**
 * @type RequestProps
 */
type RequestProps = RouteComponentProps & {
    hash?: string;
};

/**
 * @const Request
 */
const Request = (props: RequestProps) => {
    const {
        hash
    } = props;

    const formRef = createRef<HTMLFormElement>();

    const dispatch = useDispatch();
    const debounce = useDebounce();

    const areaBAG = useSelector((storeState: StoreState) => storeState.information.area);
    const areaManual = useSelector((storeState: StoreState) => storeState.information.areaManual);
    const initialZipCode = useSelector((storeState: StoreState) => storeState.information.zipCode);
    const initialHouseNumber = useSelector((storeState: StoreState) => storeState.information.houseNumber);
    const initialHouseNumberAddition = useSelector((storeState: StoreState) => storeState.information.houseNumberAddition);
    const buildingTypeCategory = useSelector((storeState: StoreState) => storeState.information.buildingTypeGroup);
    const productChoice = useSelector((storeState: StoreState) => storeState.information.productChoice);
    const promotion = useSelector((storeState: StoreState) => storeState.information.promotion);

    const [isFetching, setIsFetching] = useState(true);
    const [isSucceeded, setIsSucceeded] = useState(false);
    const [isSending, setIsSending] = useState(false);

    const [advisorId, setAdvisorId] = useState<number>();
    const [companyName, setCompanyName] = useState("...");
    const [deliveryTime, setDeliveryTime] = useState("");
    const [full, setFull] = useState(false);
    const [methodology, setMethodology] = useState("");
    const [price, setPrice] = useState("00.00");
    const [averageMarketPrice, setAverageMarketPrice] = useState("00.00");
    const [reviewAveragePercentage, setReviewAveragePercentage] = useState(0);
    const [reviewTotalCount, setReviewTotalCount] = useState(0);
    const [strongPoints, setStrongPoints] = useState<string[]>([]);
    const [formHasErrors, setFormHasErrors] = useState(false);
    const [availableReviewData, setAvailableReviewData] = useState();

    const [buildingOwnerAffix, setBuildingOwnerAffix] = useState("");
    const [buildingOwnerName, setBuildingOwnerName] = useState("");
    const [buildingOwnerInitials, setBuildingOwnerInitials] = useState("");
    const [buildingOwnerPhone, setBuildingOwnerPhone] = useState("");
    const [buildingOwnerTitle, setBuildingOwnerTitle] = useState("m");
    const [buildingOwnerZipCode, setBuildingOwnerZipCode] = useState(initialZipCode || "");
    const [buildingOwnerCompanyName, setBuildingOwnerCompanyName] = useState("");

    const [showAltBillingAddress, setShowAltBillingAddress] = useState(false);
    const [city, setCity] = useState("");
    const [email, setEmail] = useState("");
    const [extraInfoMessage, setExtraInfoMessage] = useState("");
    const [houseNumber, setHouseNumber] = useState(initialHouseNumber || "");
    const [houseNumberSuffix, setHouseNumberSuffix] = useState(initialHouseNumberAddition || "");
    const [street, setStreet] = useState("");
    const [proposition, setProposition] = useState("");

    const [billingZipCode, setBillingZipCode] = useState("");
    const [billingHouseNumber, setBillingHouseNumber] = useState("");
    const [billingHouseNumberSuffix, setBillingHouseNumberSuffix] = useState("");
    const [billingStreet, setBillingStreet] = useState("");
    const [billingCity, setBillingCity] = useState("");

    const [contactPersonTitle, setContactPersonTitle] = useState("m");
    const [contactPersonInitials, setContactPersonInitials] = useState("");
    const [contactPersonAffix, setContactPersonAffix] = useState("");
    const [contactPersonName, setContactPersonName] = useState("");
    const [contactPersonEmail, setContactPersonEmail] = useState("");
    const [contactPersonPhone, setContactPersonPhone] = useState("");

    const [altContactType, setAltContactType] = useState("owner");


    const [errors, setErrors] = useState<{ [key: string]: string[]; }>({});

    const data: Data = useStaticQuery(graphql`
        query {
            allAdvisor {
                edges {
                    node {
                        companyImageFile {
                            childImageSharp {
                                gatsbyImageData(layout: FIXED, width: 200, quality: 50)
                            }
                        }
                        databaseId
                    }
                }
            }
            allWordpressFaq {
                edges {
                    node {
                        content
                        title
                    }
                }
            }
            filter {
                options {
                    methodology {
                        label
                        value
                    }
                }
            }
            logoPlaceholder: file(relativePath: { eq: "logo-placeholder.png" }) {
                childImageSharp {
                    gatsbyImageData(layout: FIXED, width: 200, quality: 50)
                }
            }
        }
    `);

    const advisor = data.allAdvisor.edges.find((advisor) => {
        return advisor.node.databaseId == advisorId;
    });

    let methodologyLabel = "";

    data.filter.options.methodology.forEach((option) => {
        if (option.value == methodology) {
            methodologyLabel = option.label;
        }
    });

    const fetchAverageMarketPrice = async () => {
        const response = await fetch(`${ process.env.GATSBY_API_PUBLIC_URL }/average-market-price/${ hash }/${ buildingOwnerZipCode}`, {
            method: "GET"
        });

        const data = await response.json();

        if(data) {
            setAverageMarketPrice(data.price.toString());
        }
    }

    const FormatAverageMarketPriceSuffix = (suffix) => {
        if (!suffix) {
            return `00`;
        }

        if (suffix.length === 1) {
            return `${ suffix }0`;
        }

        return suffix;
    }

    const fetchPriceTiers = async () => {
        const response = await fetch(`${ process.env.GATSBY_API_PUBLIC_URL }/search`, {
            body: JSON.stringify({
                filters: {
                    priceTierHash: hash,
                }
            }),
            method: "POST"
        });

        const data = await response.json();

        setAdvisorId(data.result[0].advisor.id);
        setCompanyName(data.result[0].advisor.companyName);
        setMethodology(data.result[0].methodology);
        setPrice(data.result[0].price);
        setReviewAveragePercentage(data.result[0].advisor.reviewAveragePercentage);
        setReviewTotalCount(data.result[0].advisor.reviewTotalCount);
        setStrongPoints(data.result[0].advisor.strongPoints.map((strongPoint) => strongPoint.message));
        setDeliveryTime(data.result[0].advisor.deliveryTime);
        setFull(data.result[0].advisor.full);

        setAvailableReviewData(data.result[0]);

        dispatch(setHasCompleted(false));
        dispatch(setAdvisorUrl(data.result[0].advisor.url));

        setIsFetching(false);
    };

    const onButtonClick = async (event: MouseEvent) => {
        event.preventDefault();

        setIsSending(true);

        if (formRef.current?.reportValidity()) {

            let promotionId = null;
            if(promotion) {
                promotionId = promotion.id;
            }

            const response = await fetch(`${ process.env.GATSBY_API_PUBLIC_URL }/request`, {
                body: JSON.stringify({
                    hash: hash,
                    areaBag: areaBAG,
                    areaManual: areaManual,
                    buildingOwnerAffix: buildingOwnerAffix,
                    buildingOwnerInitials: buildingOwnerInitials,
                    buildingOwnerName: buildingOwnerName,
                    buildingOwnerPhone: buildingOwnerPhone,
                    buildingOwnerTitle: buildingOwnerTitle,
                    buildingOwnerCompanyName: buildingOwnerCompanyName,
                    promotionId: promotionId,
                    promotionAnswer: proposition,
                    zipCode: buildingOwnerZipCode,
                    houseNumber: houseNumber,
                    houseNumberSuffix: houseNumberSuffix,
                    street: street,
                    city: city,
                    email: email,
                    extraInfoMessage: extraInfoMessage,
                    requestContact: altContactType == "alternative"
                        ? {
                            firstName: contactPersonInitials,
                            affix: contactPersonAffix,
                            name: contactPersonName,
                            email: contactPersonEmail,
                            phone: contactPersonPhone
                        }
                        : null,
                    billingZipcode: billingZipCode,
                    billingHouseNumber: billingHouseNumber,
                    billingHouseNumberSuffix: billingHouseNumberSuffix,
                    billingStreet: billingStreet,
                    billingCity: billingCity,
                }),
                method: "POST"
            });

            const data = await response.json();

            if (data.status == "failed") {
                setErrors(data.errors);
            }
            else {
                const dataLayerData = {
                    "event": "conversion",
                    "client_email": email,
                    "property_type": buildingTypeCategory,
                    "product_choice": productChoice,
                };

                const addedDataLayer = window.dataLayer || [];
                typeof window == "object" && addedDataLayer.push(dataLayerData);

                typeof window == "object" && window.scrollTo(0, 0);

                setIsSucceeded(true);

                dispatch(setHasCompleted(true));
                dispatch(setHasVisited(true));
            }
        } else {
            setFormHasErrors(true);
        }

        setIsSending(false);
    };

    const onToggleBillingAddress = () => {
        if(showAltBillingAddress) {
            setBillingZipCode("");
            setBillingHouseNumber("");
            setBillingHouseNumberSuffix("");
            setBillingStreet("");
            setBillingCity("");

            setShowAltBillingAddress(false);
            return;
        }

        setShowAltBillingAddress(true);
    }

    const onToggleAltContactType = (type) => {
        if(altContactType) {
            setContactPersonTitle("m");
            setContactPersonInitials("");
            setContactPersonAffix("");
            setContactPersonName("");
            setContactPersonEmail("");
            setContactPersonPhone("");

            setAltContactType(type);
            return;
        }
        setAltContactType(type);
    };

    const fetchAddressByZipcode = async () => {
        const response = await fetch(`${ process.env.GATSBY_API_PUBLIC_URL }/bag/${ buildingTypeCategory }/${ buildingOwnerZipCode }/${ houseNumber }/${ houseNumberSuffix }`);

        const data = await response.json();

        if(data.status === "success") {
            setStreet(data.result.street);
            setCity(data.result.city);
        } else {
            setStreet("");
            setCity("");
        }
    };

    const fetchAddressAutocomplete = async () => {
        const response = await fetch(`${ process.env.GATSBY_API_PUBLIC_URL }/address-autocomplete/${ billingZipCode }/${ billingHouseNumber }/${ billingHouseNumberSuffix }`);

        const data = await response.json();

        if(data.status === "success") {
            setBillingStreet(data.result.address);
            setBillingCity(data.result.city);
        } else {
            setBillingStreet("");
            setBillingCity("");
        }
    };

    useEffect(() => {
        setIsSucceeded(false);

        void fetchPriceTiers();
        void fetchAverageMarketPrice();
        void fetchAddressByZipcode();
    }, []);

    useEffect(() => {
        if(billingZipCode && billingHouseNumber) {
            debounce(() => {
                void fetchAddressAutocomplete();
            });
        }
    }, [billingZipCode, billingHouseNumber, billingHouseNumberSuffix]);

    useEffect(() => {
        if (!(isFetching)) {
            const dataLayerData = {
                "event": "push",
                "priceTiers": [{
                    "companyName": companyName,
                    "companyID": advisorId,
                    "priceType": price ? "price" : "offer",
                    "deliveryDays": deliveryTime,
                    "availability": !(full),
                    "method": methodology,
                    "reviewScore": reviewAveragePercentage,
                    "reviewAmount": reviewTotalCount,
                    "price": ""
                }]
            };

            dataLayerData.priceTiers.forEach((priceTier, index) => {
                if (price) {
                    dataLayerData.priceTiers[index].price = price;
                }
            });

            const addedDataLayer = window.dataLayer || [];
            typeof window == "object" && addedDataLayer.push(dataLayerData);
        }
    }, [isFetching]);

    return (
        <Main className={ classNames.main }>
            <Helmet>
                <title>Je aanvraag bij { companyName }</title>
            </Helmet>
            { errors["#"] && (
                <section className={ classNames.popup }>
                    <h1 className={ classNames.heading }>Oeps, er is iets mis gegaan</h1>
                    <p className={ classNames.paragraph }>
                        <DisplayProductChoice inputText="Je hebt al een succesvolle aanvraag voor een productChoice gedaan! Voor wijzigingen in je aanvraag
                        kan je contact met ons opnemen middels onze contactgegevens onderaan de pagina." />
                    </p>
                    <Button className={ classNames.button } onClick={ () => setErrors({}) }>Ok, ik snap het</Button>
                </section>
            ) }
            <div className={ classNames.request }>
                <div className={ classNames.container }>
                    <Heading className={ classNames.heading } variant="large">
                        Je aanvraag bij <strong>{ companyName }</strong>
                    </Heading>
                    <div className={ classNames.blocks }>
                        <div className={ classNames.blockOne }>
                            { isSucceeded &&
                                <Box>
                                    <Heading className={ classNames.heading } element="h2" variant="medium">
                                        Je aanvraag is <strong>verzonden</strong>!
                                    </Heading>
                                    <p className={ classNames.paragraph }>
                                        <DisplayProductChoice inputText="Dank voor je aanvraag bij Woninglabel.nl.
                                        <br />
                                        <br />
                                        Als het goed is heb je hier een bevestiging van in je mailbox ontvangen.
                                        Mocht je de bevestigingsmail niet ontvangen hebben dan vind je deze vaak
                                        terug in de map ongewenste berichten. Let hier vooral op indien je een
                                        Hotmail- of Gmail-account hebt.<br /><br />

                                        De door jou geselecteerde productChoice-adviseur neemt zo snel mogelijk
                                        contact met je op om een afspraak voor de gebouwopname in te plannen.
                                        Voor vragen over onze dienstverlening als vergelijkingsplatform kun je
                                        ons altijd bereiken middels onze contactgegevens of door te reageren
                                        op de bevestigingsmail.
                                        <br />
                                        <br />
                                        We hopen dat je de aanvraag voor een productChoice als gemakkelijk en prettig
                                        ervaren hebt." />
                                    </p>
                                </Box>
                            }
                            { !(isSucceeded) &&
                                <>
                                    <Box className={ classNames.box }>
                                    <Heading className={ classNames.heading } element="h2" variant="medium">
                                        <DisplayProductChoice inputText="Voor <strong>welk adres</strong> wil je een productChoice?" />
                                    </Heading>
                                    <form method="POST" ref={ formRef }>
                                        <AddressFormInputs
                                            errors={ errors }
                                            initialZipCode={ initialZipCode }
                                            inputZipCode={ buildingOwnerZipCode }
                                            onSetInputZipCode={
                                                (event) => setBuildingOwnerZipCode(
                                                    event.currentTarget.value.toUpperCase()
                                                ) }
                                            initialHouseNumber={ initialHouseNumber }
                                            inputHouseNumber={ houseNumber }
                                            onSetInputHouseNumber={
                                                (event) => setHouseNumber(event.currentTarget.value)
                                            }
                                            initialHouseNumberSuffix={ initialHouseNumberAddition }
                                            inputHouseNumberSuffix={ houseNumberSuffix }
                                            onSetInputHouseNumberSuffix={
                                                (event) => setHouseNumberSuffix(event.currentTarget.value)
                                            }
                                            inputStreet={ street }
                                            onSetInputStreet={
                                                (event) => setStreet(event.currentTarget.value)
                                            }
                                            inputCity={ city }
                                            onSetInputCity={
                                                (event) => setCity(event.currentTarget.value)
                                            }
                                            buildingOwner={ true }
                                        />
                                        <div className={ classNames.requireQuote }>
                                            <input
                                                className={ classNames.checkbox }
                                                type="checkbox"
                                                name="billing_shipping_address"
                                                onChange={ () => onToggleBillingAddress() }
                                            />
                                            <span className={ classNames.altBilling }>
                                                Factuuradres is anders dan bovenstaand
                                            </span>
                                        </div>
                                        { showAltBillingAddress &&
                                            <>
                                                <Heading className={ classNames.heading } element="h2" variant="medium">
                                                    Factuuradres
                                                </Heading>
                                                <AddressFormInputs
                                                    errors={ errors }
                                                    inputZipCode={ billingZipCode }
                                                    onSetInputZipCode={
                                                        (event) => setBillingZipCode(
                                                            event.currentTarget.value.toUpperCase()
                                                        ) }
                                                    inputHouseNumber={ billingHouseNumber }
                                                    onSetInputHouseNumber={
                                                        (event) => setBillingHouseNumber(event.currentTarget.value)
                                                    }
                                                    inputHouseNumberSuffix={ billingHouseNumberSuffix }
                                                    onSetInputHouseNumberSuffix={
                                                        (event) => setBillingHouseNumberSuffix(event.currentTarget.value)
                                                    }
                                                    inputStreet={ billingStreet }
                                                    onSetInputStreet={
                                                        (event) => setBillingStreet(event.currentTarget.value)
                                                    }
                                                    inputCity={ billingCity }
                                                    onSetInputCity={
                                                        (event) => setBillingCity(event.currentTarget.value)
                                                    }
                                                    hasBillingAddress={ true }
                                                />
                                            </>
                                        }
                                        <fieldset className={ classNames.fieldset }>
                                            <Heading className={ classNames.heading } element="h2" variant="medium">
                                                <strong>Aanvraag</strong> door
                                            </Heading>
                                            <div className={ clsx(classNames.group, classNames.column) }>
                                                <InputRadio
                                                    checked={ altContactType == "owner" }
                                                    labelClassName={ classNames.inputRadio }
                                                    additionalClassName={ classNames.large }
                                                    name="requireContact"
                                                    onChange={ (event) =>
                                                        onToggleAltContactType(event.currentTarget.value)
                                                    }
                                                    // required
                                                    value="owner"
                                                >
                                                    Ik ben <u>zelf</u> de contactpersoon voor de gebouwopname
                                                </InputRadio>
                                                <InputRadio
                                                    checked={ altContactType == "alternative" }
                                                    labelClassName={ classNames.inputRadio }
                                                    additionalClassName={ classNames.large }
                                                    name="requireContact"
                                                    onChange={ (event) =>
                                                        onToggleAltContactType(event.currentTarget.value)
                                                    }
                                                    // required
                                                    value="alternative"
                                                >Iemand <u>anders</u> is de contactpersoon voor de gebouwopname</InputRadio>
                                            </div>
                                        </fieldset>
                                        <Heading className={ classNames.heading } element="h2" variant="medium">
                                            Jouw <strong>gegevens</strong>
                                        </Heading>
                                        <ContactPersonFormInputs
                                            contactTitle={ buildingOwnerTitle }
                                            onSetContactTitle={
                                                (event) => setBuildingOwnerTitle(event.currentTarget.value)
                                            }
                                            contactInitials={ buildingOwnerInitials }
                                            onSetContactInitials={
                                                (event) => setBuildingOwnerInitials(event.currentTarget.value)
                                            }
                                            contactAffix={ buildingOwnerAffix }
                                            onSetContactAffix={
                                                (event) => setBuildingOwnerAffix(event.currentTarget.value)
                                            }
                                            contactName={ buildingOwnerName }
                                            onSetContactName={
                                                (event) => setBuildingOwnerName(event.currentTarget.value)
                                            }
                                            contactPhone={ buildingOwnerPhone }
                                            onSetContactPhone={
                                                (event) => setBuildingOwnerPhone(event.currentTarget.value)
                                            }
                                            contactEmail={ email }
                                            onSetContactEmail={
                                                (event) => setEmail(event.currentTarget.value)
                                            }
                                            onShowProposition={ promotion !== null }
                                            promotion={ promotion }
                                            propositionChecked={ proposition }
                                            onSetPropositionChecked={
                                                (event) => setProposition(event.currentTarget.value)
                                            }
                                            buildingOwner={ true }
                                            contactCompanyName={ buildingOwnerCompanyName }
                                            onSetContactCompanyName={
                                                (event) => setBuildingOwnerCompanyName(event.currentTarget.value)
                                            }
                                            errors={ errors }
                                        />

                                        { altContactType == "alternative" &&
                                            <>
                                                <Heading className={ classNames.heading } element="h2" variant="medium">
                                                    Gegevens <strong>contactpersoon</strong> voor gebouwopname
                                                </Heading>
                                                <ContactPersonFormInputs
                                                    contactTitle={ contactPersonTitle }
                                                    onSetContactTitle={
                                                        (event) => setContactPersonTitle(event.currentTarget.value)
                                                    }
                                                    contactInitials={ contactPersonInitials }
                                                    onSetContactInitials={
                                                        (event) => setContactPersonInitials(event.currentTarget.value)
                                                    }
                                                    contactAffix={ contactPersonAffix }
                                                    onSetContactAffix={
                                                        (event) => setContactPersonAffix(event.currentTarget.value)
                                                    }
                                                    contactName={ contactPersonName }
                                                    onSetContactName={
                                                        (event) => setContactPersonName(event.currentTarget.value)
                                                    }
                                                    contactPhone={ contactPersonPhone }
                                                    onSetContactPhone={
                                                        (event) => setContactPersonPhone(event.currentTarget.value)
                                                    }
                                                    contactEmail={ contactPersonEmail }
                                                    onSetContactEmail={
                                                        (event) => setContactPersonEmail(event.currentTarget.value)
                                                    }
                                                    altContactData={ true }
                                                    errors={ errors }
                                                />
                                            </>
                                        }

                                        <Heading className={ classNames.heading } element="h2" variant="medium">
                                            Extra <strong>toelichting</strong>
                                        </Heading>
                                        <label className={ classNames.label } htmlFor="message">Wil je nog iets kwijt?</label>
                                        <Textarea
                                            className={ clsx(classNames.textarea, {
                                                [classNames.error]: errors.extraInfoMessage
                                            }) }
                                            id="message"
                                            name="message"
                                            onChange={ (event) => setExtraInfoMessage(event.currentTarget.value) }
                                            placeholder="Type hier je bericht. Geef optioneel verder aan of de aanvraag bestemt is voor de verkoop, verhuur of nieuwbouw van het gebouw of als onderdeel van een verduurzamingstraject."
                                            value={ extraInfoMessage }
                                        />
                                        <ul className={ classNames.errors }>
                                            { errors.extraInfoMessage &&
                                                errors.extraInfoMessage.map((error, index) => (
                                                    <li className={ classNames.error } key={ index }>{ error }</li>
                                                )) }
                                        </ul>
                                        <div className={ classNames.greyBox }>
                                            <InputCheckbox
                                                labelClassName={ classNames.inputCheckboxLabel }
                                                name="conditions"
                                                required
                                            >
                                                Door deze aanvraag te bevestigen, ga je akkoord met
                                                de <a href="https://woninglabel.nl/algemene-voorwaarden-klanten" target="_blank" rel="noreferrer">Algemene Voorwaarden – Klanten</a>.
                                            </InputCheckbox>
                                        </div>
                                        { formHasErrors &&
                                            <div className={ classNames.notification }>
                                                Er zijn één of meer velden niet ingevuld. Vul de ontbrekende gegevens aan en probeer het nog eens.
                                            </div>
                                        }
                                        <Button className={ classNames.button } disabled={ isSending } onClick={ onButtonClick } type="submit">
                                            <span className={ classNames.text }><DisplayProductChoice inputText="productChoice aanvragen" /></span>
                                            <LongArrowRightIcon className={ classNames.icon } />
                                        </Button>
                                    </form>
                                </Box>
                                { !(isFetching) && availableReviewData.advisor.reviews[0].recentReviews.length > 0 &&
                                    <AdvisorReviews advisor={ availableReviewData.advisor } variant="request" />
                                }
                            </>
                            }
                        </div>
                        { isFetching &&
                            <div className={ classNames.blockTwo }>
                                <Box className={ classNames.box }>
                                    <span className={ classNames.fetching }>Gegevens laden...</span>
                                </Box>
                            </div>
                        }
                        { !(isFetching) &&
                            <div className={ classNames.blockTwo }>
                                <Box className={ classNames.box }>
                                    <div className={ classNames.priceTierWrapper }>
                                        <Heading className={ classNames.heading } element="h2" variant="medium">
                                            Jouw <strong>totaalprijs</strong> is:
                                        </Heading>
                                        <div className={ classNames.price }>
                                            { price &&
                                                <>
                                                    <span className={classNames.integer}>{price.split(".")[0]},</span>
                                                    <span className={classNames.fraction}>{FormatAverageMarketPriceSuffix(price.split(".")[1])}</span>
                                                    <div className={classNames.includingVat}>
                                                        {buildingTypeCategory === 'private' ? 'incl.' : 'excl.'} btw
                                                    </div>
                                                </>
                                            }
                                            { !price && <span className={classNames.integer}>Offerte</span> }
                                        </div>
                                    </div>
                                    <p className={ classNames.paragraph }>
                                        <DisplayProductChoice inputText="Dit is de totaalprijs waarvoor je jouw productChoice ontvangt. Hier komen geen kosten meer bij." />
                                    </p>
                                </Box>

                                { averageMarketPrice &&
                                    <Box className={ classNames.box }>
                                        <div className={ classNames.priceTierWrapper }>
                                            <Heading className={ clsx(classNames.heading, classNames.average) } element="h2" variant="small">
                                                De <strong>gemiddelde</strong> marktpijs voor jouw situatie is:
                                            </Heading>
                                            <div className={ clsx(classNames.price, classNames.average) }>
                                                <span className={ classNames.integer }>{ averageMarketPrice.split(".")[0] },</span>
                                                    <span className={ classNames.fraction }>{ !averageMarketPrice.split(".")[1] ? "00" : `${averageMarketPrice.split(".")[1]}` }</span>
                                                    <div className={ classNames.includingVat }>
                                                        { buildingTypeCategory === 'private' ? 'incl.' : 'excl.' } btw
                                                    </div>
                                            </div>
                                        </div>
                                        <p className={ classNames.paragraph }>
                                            De gemiddelde marktprijs bepalen we door tarieven voor jouw situatie - op basis van: het postcodegebied, het type gebouw, de oppervlakte van het gebouw en de opnamemethodiek - op te halen uit onze eigen database en een ruim aantal externe kanalen. Waaruit we vervolgens het gemiddelde berekenen.
                                        </p>
                                    </Box>
                                }

                                <Box className={ classNames.box }>
                                    <div className={ classNames.header }>
                                        <Heading element="h3" variant="small" className={ classNames.why }>
                                            Waarom <strong>{ companyName }</strong>?
                                        </Heading>
                                        { advisor?.node.companyImageFile?.childImageSharp?.gatsbyImageData &&
                                            <GatsbyImage
                                                alt={ companyName }
                                                className={ classNames.logo }
                                                image={ advisor.node.companyImageFile.childImageSharp.gatsbyImageData }
                                            />
                                        }
                                        { !(advisor?.node.companyImageFile?.childImageSharp?.gatsbyImageData) &&
                                            <GatsbyImage
                                                alt={ companyName }
                                                className={ classNames.logo }
                                                image={ data.logoPlaceholder.childImageSharp.gatsbyImageData }
                                            />
                                        }

                                    </div>
                                    <ul className={ classNames.list }>
                                        { strongPoints.map((strongPoint) => (
                                            <li className={ classNames.listItem } key={ strongPoint }>
                                                <CheckIcon className={ classNames.icon } />
                                                <span className={ classNames.text }>{ strongPoint }</span>
                                            </li>
                                        )) }
                                    </ul>
                                </Box>

                                { !(isFetching) && availableReviewData.advisor.reviews[0].recentReviews.length > 0 &&
                                    <Box className={clsx(classNames.box, classNames.boxReviews)}>
                                        <Heading element="h3" variant="small" className={classNames.title}>
                                            Klantervaringen bij <strong>{companyName}</strong>
                                        </Heading>
                                        <AdvisorReviewsScore
                                            averageReviewsScore={ availableReviewData.advisor.reviews[0].stats.averageScore }
                                            averageReviewsTotal={ availableReviewData.advisor.reviews[0].stats.totalReviews }
                                            reviewScore={ availableReviewData.advisor.reviews[0].scores }
                                            variant="request"
                                        />
                                    </Box>
                                }

                                { data.allWordpressFaq.edges.length > 0 &&
                                    <Box className={ clsx(classNames.box, classNames.boxFaqs) }>
                                        <Heading className={ clsx(classNames.heading, classNames.faqs) } element="h2" variant="small">
                                            Veelgestelde <strong>vragen</strong>
                                        </Heading>
                                        <SiteFaqs faqs={ data.allWordpressFaq.edges } />
                                    </Box>
                                }
                            </div>
                        }
                    </div>
                </div>
            </div>
        </Main>
    );
};

export default Request;
