import React from "react";

import { DetailedHTMLProps } from "react";
import { InputHTMLAttributes } from "react";

import clsx from "clsx";

import CheckIcon from "@js/component/icon/CheckIcon";

import * as classNames from "@css/component/InputCheckbox.module.scss";

/**
 * @type InputCheckboxProps
 */
export type InputCheckboxProps = DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & {
    labelClassName?: string;
};

/**
 * @const InputCheckbox
 */
const InputCheckbox = (props: InputCheckboxProps) => {
    const {
        children,
        className,
        labelClassName,
        ...restProps
    } = props;

    return (
        <label className={ clsx(classNames.label, labelClassName) }>
            <input { ...restProps } className={ classNames.input } type="checkbox" />
            <div className={ clsx(classNames.inputCheckbox, className) }>
                <CheckIcon className={ classNames.icon } />
            </div>
            <div className={ classNames.inputCheckboxLabel }>
                { children }
            </div>
        </label>
    );
};

export default InputCheckbox;
