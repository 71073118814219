// extracted by mini-css-extract-plugin
export var addition = "AddressFormInput-module--addition--4WQUt";
export var buildingOwnerAffix = "AddressFormInput-module--buildingOwnerAffix--5H22c";
export var buildingOwnerInitials = "AddressFormInput-module--buildingOwnerInitials--o9UEA";
export var error = "AddressFormInput-module--error--vEKra";
export var errors = "AddressFormInput-module--errors--F+9nJ";
export var group = "AddressFormInput-module--group--zrAaB";
export var houseNumber = "AddressFormInput-module--houseNumber--Etqh8";
export var input = "AddressFormInput-module--input--4VnP7";
export var label = "AddressFormInput-module--label--r2V6j";
export var labelAddition = "AddressFormInput-module--labelAddition--Ezhsp";
export var labelBuildingOwnerAffix = "AddressFormInput-module--labelBuildingOwnerAffix--ZbV8O";
export var labelBuildingOwnerInitials = "AddressFormInput-module--labelBuildingOwnerInitials--Oqp0E";
export var labelHouseNumber = "AddressFormInput-module--labelHouseNumber--POpcH";
export var textarea = "AddressFormInput-module--textarea--IPDoy";
export var zipCode = "AddressFormInput-module--zipCode--UhWJL";